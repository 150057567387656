import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "../lottie/loadingAnimation.json";
import waitingOnInput from "../lottie/waitingOnInput.json";
import { backendURL } from "../constants/urls";

export default function CreatePage() {
  const navigate = useNavigate();
  const [isCreatingStory, setIsCreatingStory] = useState(false);
  const [, setError] = useState("");
  const fixedInputClass =
    "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm";
  const createStoryURL = `${backendURL}/story/`;
  const handleStoryCreate = async ({
    genre,
    protagonist,
    others,
    theme,
    rules,
    length,
  }) => {
    setIsCreatingStory(true);
    await fetch(createStoryURL.toString(), {
      method: "POST",
      body: JSON.stringify({
        genre: genre,
        protagonist: protagonist,
        others: others,
        theme: theme,
        rules: rules,
        length: length,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        navigate(`/story/${data.id}/`);
      })
      .catch((error) => {
        setError(error ? JSON.stringify(error) : "");
      });
    setIsCreatingStory(false);
  };
  return (
    <>
      <Navbar active="Create" />
      <div className="flex items-center justify-center">
        <div className="max-w-md w-full py-12 px-8">
          <Formik
            className="mt-8 space-y-6"
            initialValues={{
              genre: "",
              protagonist: "",
              others: "",
              theme: "",
              rules: "",
              length: "short",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.genre) {
                errors.genre = "A genre is required";
              }
              if (!values.protagonist) {
                errors.protagonist = "A protagonist is required";
              }
              if (!values.theme) {
                errors.theme = "A theme is required";
              }
              if (!values.rules) {
                errors.rules = "Ground rules are required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleStoryCreate(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ isSubmitting }) =>
              isCreatingStory ? (
                <>
                  <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900">
                    Creating Your Story
                  </h1>
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Wait a moment while we create your story...
                  </h2>

                  <Player
                    src={loadingAnimation}
                    style={{ height: "300px" }}
                    autoplay
                    loop
                  />
                </>
              ) : isSubmitting ? (
                <>
                  <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900">
                    Submitting Your Story Request
                  </h1>
                </>
              ) : (
                <>
                  <Player
                    src={waitingOnInput}
                    style={{ height: "100px" }}
                    autoplay
                    loop
                  />
                  <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900">
                    Create a Story
                  </h1>

                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    What kind of story would you like to create?
                  </h2>
                  <Form className="mt-8 space-y-6">
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Genre"}
                      </h3>
                      <div className="my-5">
                        <Field
                          type="text"
                          name="genre"
                          placeholder="A heroic tale"
                          className={fixedInputClass}
                        />
                      </div>
                    </div>
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Main Protagonist"}
                      </h3>
                      <div className="my-5">
                        <Field
                          type="text"
                          name="protagonist"
                          placeholder="Link"
                          className={fixedInputClass}
                        />
                      </div>
                    </div>
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Other Characters"}
                      </h3>
                      <div className="my-5">
                        <Field
                          type="text"
                          name="others"
                          placeholder="Zelda, Gannon, and Navi"
                          className={fixedInputClass}
                        />
                      </div>
                    </div>
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Theme"}
                      </h3>
                      <div className="my-5">
                        <Field
                          type="text"
                          name="theme"
                          placeholder="The main protagonist solves a very tricky puzzle in a cave"
                          className={fixedInputClass}
                        />
                      </div>
                    </div>
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Important Ground Rules"}
                      </h3>
                      <div className="my-5">
                        <Field
                          type="text"
                          name="rules"
                          placeholder="Make sure the story involves using a magical spinning sword"
                          className={fixedInputClass}
                        />
                      </div>
                    </div>
                    <div className="-space-y-px">
                      <h3 className="mt-6 font-extrabold text-gray-900">
                        {"Length"}
                      </h3>
                      <div className="my-5">
                        <Field
                          as="select"
                          name="length"
                          className={fixedInputClass}
                        >
                          <option value="short">Short</option>
                          <option value="medium">Medium</option>
                          <option value="long">Long</option>
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage name="genre" component="div" />
                    <ErrorMessage name="protagonist" component="div" />
                    <ErrorMessage name="others" component="div" />
                    <ErrorMessage name="theme" component="div" />
                    <ErrorMessage name="rules" component="div" />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 mt-10"
                    >
                      Create
                    </button>
                  </Form>
                </>
              )
            }
          </Formik>
        </div>
      </div>
    </>
  );
}
