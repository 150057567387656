import { loginFields } from "../constants/formFields";
import { Formik, Form, Field } from "formik";
import { backendURL } from "../constants/urls";

const fields = loginFields;
const fixedInputClass =
  "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm";
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {
  const userCreateURL = `${backendURL}/basic/user/`
  const handleCreateUser = async ({ email, password }) => {
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    await fetch(userCreateURL, {
      method: "POST",
      body: data,
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.json().message);
      }
      window.location.reload();
    })
    .catch(() => {
      console.log("Ran into an error while attempting to create user");
    });
  };

  return (
    <Formik
      className="mt-8 space-y-6"
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        }
        if (!values.password) {
          errors.password = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleCreateUser(values);
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="mt-8 space-y-6">
          <div className="-space-y-px">
            <div className="my-5">
              <Field
                type="text"
                name="email"
                placeholder="Email Address*"
                className={fixedInputClass}
              />
            </div>
          </div>
          <div className="-space-y-px">
            <div className="my-5">
              <Field
                type="password"
                name="password"
                placeholder="Password*"
                className={fixedInputClass}
              />
            </div>
          </div>
          <div className="-space-y-px">
            <div className="my-5">
              <Field
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password*"
                className={fixedInputClass}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
          >
            Signup
          </button>
        </Form>
      )}
    </Formik>
  );
}
