import { useState } from "react";
import Header from "../components/Header";
import Login from "../components/Login";
import Signup from "../components/Signup";

export default function LoginPage() {
  const [showSignup, setShowSignup] = useState()
  const toggleForm = () => {
    setShowSignup(!showSignup)
  }
  return (
    <div className="min-h-full bg-gradient-to-r from-red-200 to-orange-200 h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <Header
          heading={showSignup ?  "Signup for an account" : "Login to your account"}
          paragraph={showSignup ? "Already have an account? " : "Don't have an account yet? "}
          linkName={showSignup ? "Login" : "Signup"}
          handleLinkClick={toggleForm}
        />
        {showSignup ? <Signup /> : <Login />}
      </div>
    </div>
  );
}
