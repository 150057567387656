import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "../lottie/loadingAnimation.json";
import StoryDisplay from "../components/StoryDisplay"
import { backendURL } from "../constants/urls";

export default function StoryPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [story, setStory] = useState();
  const [error, setError] = useState();
  const storyURL = `${backendURL}/story/${id}`;
  useEffect(() => {
    setIsLoading(true);
    const fetchStory = async () => {
      await fetch(storyURL.toString(), {
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.json().message);
          }
          return response.json();
        })
        .then((data) => setStory(data))
        .catch((err) => setError(err));
    };
    fetchStory();
    setIsLoading(false);
  }, []);
  return (
    <>
      <Navbar />
      {isLoading ? (
        <Player
          src={loadingAnimation}
          style={{ height: "300px" }}
          autoplay
          loop
        />
      ) : error ? (
        JSON.stringify(error)
      ) : (
        story ? StoryDisplay(story) : <Player
        src={loadingAnimation}
        style={{ height: "300px" }}
        autoplay
        loop
      />
      )}
    </>
  );
}
