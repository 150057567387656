import Navbar from "../components/Navbar";
import { Player } from "@lottiefiles/react-lottie-player";
import parents from "../lottie/parents.json";

export default function AboutUsPage() {
  return (
    <>
      <Navbar active="About Us" />
      <div className="flex items-center justify-center">
        <div className="px-14 py-2 w-full">
          <Player src={parents} style={{ height: "250px" }} autoplay keepLastFrame speed={0.8} />
          <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900 py-5">
            Why We Created Golden Stories
          </h1>
          <blockquote className="mt-6 text-2xl text-gray-900">
            <div style={{ whiteSpace: "pre-line" }}>
              <p className="mb-3 text-gray-500">
                Golden stories is a tool for parents, by parents. Our primary
                purpose is to put the control of content consumption by children
                into the hands of their parents--where it should be!
              </p>
              <p className="mb-3 text-gray-500">
                In today's world, content is ubiquitous and content consumption
                is rapid. Long gone are the days when kids would wait an entire
                week to wake up at the crack of dawn on Saturday to partake in
                the infamous "Saturday morning cartoons" ritual.
              </p>
              <p className="mb-3 text-gray-500">
                Likewise, family trips to book stores are becoming
                increasingly rare as any device with access to the internet can
                serve as a portal to unlimited reading material. In many ways,
                this scares reasonable parents who are acutely aware of the
                dangers. Many of us are performing a balancing act of asserting
                control over what our children are reading while also not
                discouraging them or dampening the kinds of creativity that can
                be stimulated by free exploration.
              </p>
              <p className="mb-3 text-gray-500">
                Using guidance and constraints, Golden Stories empowers you to use
                powerful artifical intelligence models to create exactly the kind of
                children stories you want. We hope you enjoy creating and reading
                golden stories with your little ones and remember to share your favorite
                ones with others!
              </p>
            </div>
          </blockquote>
        </div>
      </div>
    </>
  );
}
