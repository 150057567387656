export default function StoryPage({ title, content, user, created_at }) {
  const date = new Date(created_at)
  return (
    <div className="flex justify-center py-14 bg-white">
      <div className="px-14 py-2 w-full">
        <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900 py-5">
          {title}
        </h1>
        <h3 className="py-2 text-2xl text-yellow-600">{`Created ${date.toDateString()} by ${user.email}`}</h3>
        <blockquote className="text-xl font-semibold text-gray-900">
          <div style={{ whiteSpace: "pre-line" }}>
            <p>{content}</p>
          </div>
        </blockquote>
      </div>
    </div>
  );
}
