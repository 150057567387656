import React from "react";

export default function Services() {
  return (
    <section id="services" className="py-10 md:py-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl font-bold mb-4">
            Putting Control in the Hands of Parents
          </h2>
          <p className="text-lg sm:text-2xl mb-6 md:mb-14">
            Craft Your Child's Dream Tale - One AI-Generated Story at a Time!
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-8 xl:gap-10">
          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-pen text-4xl"></i>
              <h2 className="card-title">Personalized Story Creation</h2>
              <p>
                Just provide the names of your protagonists, set the ground
                rules, choose themes, and let our AI weave unique and enchanting
                tales.
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-book-half text-4xl"></i>
              <h2 className="card-title">Diverse Themes & Genres</h2>
              <p>
                From magical kingdoms to space adventures, select themes that
                resonate with your child’s interests.
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-share text-4xl"></i>
              <h2 className="card-title">Share Your Stories</h2>
              <p>
                Share your favorite stories with friends & family by sending
                them a direct link.
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-people text-4xl"></i>
              <h2 className="card-title">Family Involvement</h2>
              <p>
                Involve the whole family in the story creation process for a fun
                and engaging experience.
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-ui-checks-grid text-4xl"></i>
              <h2 className="card-title">Easy-to-Use Interface</h2>
              <p>
                Our user-friendly platform ensures a seamless experience from
                story conception to final product.
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              <i className="bi bi-cloud-arrow-down text-4xl"></i>
              <h2 className="card-title">Accessible Anywhere</h2>
              <p>
                Create and access your stories from any device, at any time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
