export default function Header({
  heading,
  paragraph,
  linkName,
  handleLinkClick,
}) {
  return (
    <div className="mb-10">
      <div className="flex justify-center">
        <img
          src={process.env.PUBLIC_URL + "/golden-stories.png"}
          alt="golden-stories"
          style={{ borderWidth: "0 0 6px", borderColor: "black" }}
        />
      </div>
      <h1 className="mt-6 text-center text-5xl font-extrabold text-gray-900">
        {"Golden Stories"}
      </h1>
      {process.env.REACT_APP_ENABLE_BASIC_AUTH === "true" ? (
        <>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {heading}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 mt-5">
            {paragraph}{" "}
            <button
              onClick={handleLinkClick}
              className="font-medium text-purple-600 hover:text-purple-500"
            >
              {linkName}
            </button>
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
