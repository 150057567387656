import React from "react";

export default function Header() {
  document.documentElement.setAttribute("data-theme", "pastel");
  return (
    <header className="bg-base-100 py-2 sticky top-0 z-50">
      <div className="container">
        <div className="navbar px-0">
          <div className="navbar-start">
            <div className="dropdown">
              <label
                tabIndex={0}
                className="btn btn-circle btn-primary lg:hidden mr-1"
              >
                <i className="bi bi-list text-2xl"></i>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content mt-1 w-52 menu menu-compact p-2 bg-base-200 shadow rounded-box"
              >
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#testimonials">Testimonials</a>
                </li>
                <li>
                  <a href="/signup">Signup</a>
                </li>
              </ul>
            </div>
            <a href="/" className="btn btn-ghost normal-case text-2xl">Golden Stories</a>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal p-0 font-medium">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#services">Features</a>
              </li>
              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="/signup">Signup</a>
              </li>
            </ul>
          </div>
          <div className="navbar-end">
            <div className="dropdown dropdown-end">
              <a href="/login">
                <label className="btn">Login</label>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
