import React from "react";
import Navbar from "../components/Navbar";
import { Player } from "@lottiefiles/react-lottie-player";
import walkingPencil from "../lottie/walkingPencil.json";

export default function HomePage() {
  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center">
        <div className="max-w-md w-full pb-12">
          <Player
            src={walkingPencil}
            style={{ height: "250px" }}
            autoplay
            loop
          />
          <h1 className="mt-3 text-center text-5xl font-extrabold text-gray-900">
            Create stories for your children
          </h1>
          <h1 className="mt-3 text-center text-2xl font-extrabold text-gray-900">
            On your own terms, with no compromises.
          </h1>
          <div className="mt-3 text-center text-2xl py-5 font-extrabold text-gray-900">
            <a href="/create">
              <button
                type="button"
                className="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2"
              >
                Get Started
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
