import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import StoryCard from "../components/StoryCard";
import { Player } from "@lottiefiles/react-lottie-player";
import planeFlyingAroundWorld from "../lottie/planeFlyingAroundWorld.json";
import loadingAnimation from "../lottie/loadingAnimation.json";
import { backendURL } from "../constants/urls";

export default function HistoryPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [stories, setStories] = useState();
  const [error, setError] = useState();
  const storiesURL = `${backendURL}/stories/`
  const noStories = (
    <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          We've searched all over the world and couldn't find a single one of
          your stories.
        </h1>
        <p className="mt-6 text-center text-xl font-extrabold text-gray-900">
          Try heading over to the <a href="/create" className="text-blue-400">create</a> page!
        </p>
        <Player
          src={planeFlyingAroundWorld}
          style={{ height: "500px" }}
          autoplay
          loop
        />
      </div>
    </div>
  );
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      const fetchStories = async () => {
        await fetch(storiesURL, {
          credentials: "include",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.json().message);
            }
            return response.json();
          })
          .then((data) => setStories(data))
          .catch((err) => setError(err));
      };
      fetchStories();
      setIsLoading(false);
    }, 500);
  }, []);
  return (
    <>
      <Navbar active="History" />
      {isLoading ? (
        <div className="flex justify-center">
          <Player
            src={loadingAnimation}
            style={{ height: "300px" }}
            autoplay
            loop
          />
        </div>
      ) : error ? (
        JSON.stringify(error)
      ) : stories ? (
        stories.length === 0 ? (
          noStories
        ) : (
    <div className="flex justify-center py-5">

          <div className="grid grid-flow-row gap-2 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {stories.map((story) => (
              <StoryCard key={story.id} {...story} />
            ))}
          </div>
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
}
