import { loginFields } from "../constants/formFields";
import { Formik, Form, Field } from "formik";
import { backendURL } from "../constants/urls";

const fields = loginFields;
const fixedInputClass =
  "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm";
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login() {
  const basicLoginURL = `${backendURL}/basic/login/`;
  const googleLoginURL = `${backendURL}/google/login/`;
  const githubLoginURL = `${backendURL}/github/login/`;
  const handleBasicLogin = async ({ email, password }) => {
    const data = new FormData();
    data.append("username", email);
    data.append("password", password);
    await fetch(basicLoginURL, {
      method: "POST",
      body: data,
      credentials: "include",
    }).catch((error) => {
      console.log("Ran into an error while attempting to login using basic auth");
    });
  };

  return (
    <>
      {process.env.REACT_APP_ENABLE_BASIC_AUTH === "true" ? (
        <Formik
          className="mt-8 space-y-6"
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleBasicLogin(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-6">
              <div className="-space-y-px">
                <div className="my-5">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email Address*"
                    className={fixedInputClass}
                  />
                </div>
              </div>
              <div className="-space-y-px">
                <div className="my-5">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password*"
                    className={fixedInputClass}
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 mt-10"
              >
                Login
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
      {/* {process.env.REACT_APP_ENABLE_GOOGLE_OAUTH === "true" ? (
        <div className="flex w-full py-5 justify-center items-center">
          <form action={googleLoginURL}>
            <input
              type="submit"
              className="login-with-google-btn rounded-sm"
              value="Sign in With Google"
            />
          </form>
        </div>
      ) : (
        <></>
      )} */}
      <div className="flex w-full py-5 justify-center items-center">
        <form action={googleLoginURL}>
          <input
            type="submit"
            className="login-with-google-btn rounded-sm"
            value="Sign in With Google"
          />
          <input type="hidden" name="target" value={window.location.pathname} />
        </form>
      </div>
      {process.env.REACT_APP_ENABLE_GITHUB_OAUTH === "true" ? (
        <div className="flex w-full py-2 justify-center items-center">
          <a href={githubLoginURL}>
            <img
              className="rounded-lg"
              style={{ width: 200 }}
              src={process.env.PUBLIC_URL + "/sign-in-with-github.png"}
              alt="github"
            />
          </a>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
