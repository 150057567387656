import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import CreatePage from "./pages/Create";
import HistoryPage from "./pages/History";
import AboutUsPage from "./pages/AboutUs";
import StoryPage from "./pages/Story";
import { CookiesProvider, useCookies } from "react-cookie";
import LandingPage from "./pages/Landing";

function App() {
  const [cookies] = useCookies(["__gslif"]);
  return (
        <CookiesProvider>
          <BrowserRouter>
            {cookies.__gslif || process.env.REACT_DISABLE_AUTH === "true" ? (
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Navigate replace to="/" />} />
                <Route path="/signup" element={<Navigate replace to="/" />} />
                <Route path="/create" element={<CreatePage />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/story/:id" element={<StoryPage />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<LoginPage />} />
              </Routes>
            )}
          </BrowserRouter>
        </CookiesProvider>
  );
}

export default App;
