import React from 'react';
import '../App.css';

import Header from '../components/LandingHeader';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Testimonial from '../components/Testimonial';
import CTA from '../components/CTA';
import Footer from '../components/Footer';

export default function LandingPage() {
  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Testimonial />
      <CTA />
      <Footer />
    </>
  );
}
