import { backendURL } from "../constants/urls";
import { useNavigate } from "react-router-dom";

export default function StoryCard({ id, title, content, created_at }) {
  const navigate = useNavigate();
  const date = new Date(created_at);
  return (
    <div className="max-w-lg p-6 bg-white border border-gray-200 rounded-lg shadow">
      <a href="#">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          {title}
        </h5>
      </a>
      <p className="mb-3 font-normal text-gray-700">
        {content ? content.substr(0, 120) + "\u2026" : "No Content to Display."}
      </p>
      <p className="mb-3 italic font-normal text-gray-700">
        {date.toDateString()}
      </p>
      <div className="flex justify-between items-center">
        <a
          href={`/story/${id}/`}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-700 rounded-lg hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300"
        >
          Read
          <svg
            className="w-3.5 h-3.5 ml-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
        <button
          onClick={() => {
            fetch(`${backendURL}/story/${id}/`, {
              method: "DELETE",
              credentials: "include",
            }).then((response) => {
              if (response.ok) {
                window.location.reload();
              }
            });
          }}
          className="px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
