import React from 'react';

export default function Testimonial()  {
  return (
    <section id="testimonials" className='py-10 md:py-16 bg-neutral'>
        <div className='container'>
          <div className='text-center text-white'>
            <h2 className='text-3xl sm:text-5xl font-bold mb-4'>Hear What Others Are Saying About Golden Stories!</h2>
            <p className='text-lg sm:text-2xl mb-6 md:mb-14'>
            
            </p>
          </div>

          <div className='columns-1 sm:columns-2 lg:columns-3 gap-6 lg:gap-8'>
            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/100/100/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Susan</p>
                        <small>Elementary School Teacher</small>
                      </div>
                    </div>
                    <p>
                    I've used Golden Stories in my classroom, and the level of engagement from my students is incredible. They love seeing their ideas come to life in these stories. It's a fantastic educational tool.
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>

            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/99/99/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Ashley</p>
                        <small>Parent</small>
                      </div>
                    </div>
                    <p>
                    Golden Stories has brought so much joy to our family nights! The stories are wonderfully unique and perfectly tailored to my kids' interests. It's our new favorite tradition!
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>

            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/98/98/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Martha</p>
                        <small>Librarian</small>
                      </div>
                    </div>
                    <p>
                    As a librarian, I've seen countless children's books, but Golden Stories offers something special – personalized adventures that kids adore. It's a hit in our reading sessions!
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>

            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/97/97/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Miguel</p>
                        <small>Child Psychologist</small>
                      </div>
                    </div>
                    <p>
                    The personalized aspect of Golden Stories is a game-changer. It fosters creativity and emotional development in children by making them a part of the storytelling process.
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>

            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/96/96/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Timothy</p>
                        <small>Author and Professor</small>
                      </div>
                    </div>
                    <p>
                    The technology behind Golden Stories is impressive. Not only does it create fantastic stories for my kids, but it's also so easy and fun to use. It's a big hit in our household!
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>

            <div className='block mb-8 lg:mb-10'>
              <div className='stack'>
                <div className="card bg-secondary text-secondary-content">
                  <div className="card-body gap-4">
                    <div className='flex items-center'>
                      <div className="avatar flex-shrink-0">
                        {/* <div className="w-16 rounded-full border-2 border-white">
                          <img src="https://placeimg.com/95/95/people" alt="person"/>
                        </div> */}
                      </div>

                      <div className='ml-3'>
                        <p className='text-lg font-medium leading-tight'>Loraine</p>
                        <small>Babysitter</small>
                      </div>
                    </div>
                    <p>
                    Golden Stories is a brilliant concept! It's amazing how the AI can create such diverse and engaging tales. It's inspiring for me as an author to see storytelling evolve this way.
                    </p>
                  </div>
                </div>
                <div className='card bg-primary h-10'></div>
                <div className='card bg-warning h-10'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
